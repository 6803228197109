import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: "Home",
};

export const navSlice = createSlice({
	name: "nav",
	initialState,
	reducers: {
		setNav: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setNav } = navSlice.actions;
export default navSlice.reducer;
