import React from "react";
import "./SimScreen.css";
import { setNav } from "@/redux/slices/navSlice";
import { useDispatch } from "react-redux";

function SimScreen({ screenRef, setMonitor }) {
	const dispatch = useDispatch();

	const handleClick = () => {
		setMonitor(screenRef);
		dispatch(setNav("Hobby"));
	};

	return (
		<div
			className='sim_screen'
			onClick={handleClick}>
			<iframe
				src='https://www.youtube.com/embed/YReHaLN6TeE?si=KKCpfzOfNN-UP6Da'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				referrerPolicy='strict-origin-when-cross-origin'
				allowFullScreen></iframe>
		</div>
	);
}

export default SimScreen;
