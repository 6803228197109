import React from "react";
import "./Loading.css";
import Layout from "@/layout/Main";

function Loading() {
	return (
		<>
			<Layout>
				<div className='arc'></div>
				<h1 className='loading_text'>
					<span>LOADING</span>
				</h1>
			</Layout>
		</>
	);
}

export default Loading;
