import "./About.css";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHtml5,
	faCss3Alt,
	faJs,
	faNode,
	faEnvira,
	faReact,
	faJava,
} from "@fortawesome/free-brands-svg-icons";
import Layout from "@/layout/Main/Main";

import AboutButton from "./component/AboutButton";
import { AboutMe } from "./AboutContent/AboutMe";
import { Education } from "./AboutContent/AboutMe";

//Redux
import { setNav } from "@/redux/slices/navSlice";
import { useDispatch } from "react-redux";

const SKILLS = [
	{
		title: "HTML",
		icon: faHtml5,
		color: "#e34c26",
	},
	{
		title: "CSS",
		icon: faCss3Alt,
		color: "#264de4",
	},
	{
		title: "JavaScript",
		icon: faJs,
		color: "#f7df1e",
	},
	{
		title: "Node",
		icon: faNode,
		color: "#3c873a",
	},
	{
		title: "Mongo",
		icon: faEnvira,
		color: "#13aa52",
	},
	{
		title: "React",
		icon: faReact,
		color: "#61dbfb",
	},
	{
		title: "Java",
		icon: faJava,
		color: "#ff0000",
	},
];

const SkillContent = () => {
	return (
		<div className='skills'>
			{SKILLS.map((skill) => (
				<div
					key={skill.title}
					className='skill'>
					<div className='icon_container'>
						<FontAwesomeIcon
							icon={skill.icon}
							size='2xl'
							style={{ color: skill.color }}
						/>
					</div>
					<span className='skill_title'>{skill.title}</span>
				</div>
			))}
		</div>
	);
};

const BUTTONS = [
	{
		title: "About Me",
		content: AboutMe,
	},
	{
		title: "Education",
		content: Education,
	},
	{
		title: "Skills",
		content: <SkillContent />,
	},
];

function About({ screenRef, setMonitor }) {
	const [title, setTitle] = useState("About Me");
	const [content, setContent] = useState(BUTTONS[0].content);

	const dispatch = useDispatch();

	const handleClick = () => {
		setMonitor(screenRef);
		dispatch(setNav("About"));
	};

	const changeContent = (title, content) => {
		setTitle(title);
		setContent(content);
	};

	return (
		<section
			className='about'
			onClick={handleClick}>
			<div className='container app_padding'>
				<div className='about_nav'>
					{BUTTONS.map((button) => (
						<AboutButton
							key={button.title}
							changeContent={changeContent}
							title={title}
							button={button}
						/>
					))}
				</div>
				<div className='about_content'>
					<div className='content_title'>{title}</div>
					<div className='content'>{content}</div>
				</div>
			</div>
		</section>
	);
}

export default About;
