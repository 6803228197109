import Section from "@/mobile/Component/Section";
import React from "react";
import Contact from "@/features/Contact";
import "./MobileContact.css";

function MobileContact() {
	return (
		<Section>
			<Contact />
		</Section>
	);
}

export default MobileContact;
