export const PROJECTS = [
	{
		title: "ActionAtlas - Organize Your Life ",
		description:
			"A fullstack todo-list app inspired by Codédex HTML, CSS, and JavaScript project",
		image: "/images/actionAtlas.png",
		stack: ["React", "Node", "MongoDB"],
		live_link: "https://action-atlas.vercel.app",
		github_link: "https://github.com/sametj/ActionAtlas",
	},
	{
		title: "Sip&Play - Built in 24Hrs",
		description:
			"A team project built for the 2024 codedex 24hr summer hackathon",
		image: "/images/after-spinplay.png",
		stack: ["React", "TypeScript", "Three.js", "Scss"],
		live_link: "https://sip-n-play-nyc.vercel.app",
		github_link: "https://github.com/Prometheus-Spark/hackathon-2024",
	},
];
