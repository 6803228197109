import React from "react";
import Section from "@/mobile/Component/Section";
import LandingPage from "@/features/Landing";

function Home() {
	return (
		<Section>
			<LandingPage />
		</Section>
	);
}

export default Home;
