import React from "react";

function ProjectCard({
	image,
	title,
	description,
	stack,
	live_link,
	github_link,
}) {
	return (
		<div className='project_card animation'>
			<img
				className='card_image'
				src={image}
				alt={title}
			/>
			<h4 className='card_title'>{title}</h4>
			<span className='subheading'> Stack</span>
			<span className='card_stack'> {stack.join(" | ")} </span>
			<span className='subheading'>Description</span>
			<span className='card_description'>{description}</span>
			<div className='link_container'>
				<a
					href={live_link}
					target='_blank'
					rel='noreferrer'
					className='live_preview'>
					Live Preview
				</a>
				<a
					target='_blank'
					rel='noreferrer'
					href={github_link}
					className='github'>
					Github
				</a>
			</div>
		</div>
	);
}

export default ProjectCard;
