import {
	Stars,
	MeshReflectorMaterial,
	Center,
	CameraControls,
} from "@react-three/drei";
import {} from "@react-three/drei";
import React, { useState, useRef, useEffect } from "react";
import TeejaysRoom from "@/models/TeejaysRoom";
import TeejaysRoomBaked from "@/models/TeejaysRoom_Baked";
import { Perf } from "r3f-perf";

import { useFrame } from "@react-three/fiber";

import { useCameraControls } from "./hooks/CameraControls";
import { useSelector } from "react-redux";

//Redux
import { setNav } from "./redux/slices/navSlice";
import { useDispatch } from "react-redux";

const DisableRender = () => {
	useFrame(() => null), 1000;
};

function Experience({ inView }) {
	const [activeScreen, setActiveScreen] = useState();
	const [active, setActive] = useState(false);

	const dispatch = useDispatch();
	const currentNav = useSelector((state) => state.nav.value);
	const closeButton = document.querySelector(".close");
	const cameraRef = useCameraControls(active, activeScreen, currentNav);
	const directionalLight = useRef();

	const resetCamera = () => {
		dispatch(setNav("Home"));
		cameraRef.current?.reset(true);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Escape") {
			resetCamera();
		}
	};

	const showLandingPage = () => {
		const landingPage = document.querySelector(".landing_page");
		document.querySelector(".nav_container").style.display = "none";
		landingPage.classList.add("fade_in");
		landingPage.style.display = "flex";

		landingPage.addEventListener(
			"transitionend",
			() => {
				landingPage.classList.remove("fade_in");
			},
			{ once: true }
		);
	};

	// useHelper(directionalLight, DirectionalLightHelper, "cyan");

	useEffect(() => {
		if (activeScreen === null) {
			resetCamera();
		}
		document.addEventListener("keydown", handleKeyDown);
		closeButton.addEventListener("click", handleXClick);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
			closeButton.removeEventListener("click", resetCamera);
		};
	}, [activeScreen]);

	const handleXClick = () => {
		if (cameraRef.current?._lastDistance < 7) {
			resetCamera();
		} else if (cameraRef.current?._lastDistance > 7 && currentNav !== "Home") {
			resetCamera();
		} else {
			showLandingPage();
		}
	};

	return (
		<>
			{!inView && <DisableRender />}

			{/* <Perf
				position='top-right'
				style={{
					position: "absolute",

					zIndex: 10000000000000,
				}}
			/> */}
			<Stars
				radius={100}
				depth={50}
				count={1000}
				factor={4}
				saturation={0}
				fade
			/>

			<CameraControls
				ref={cameraRef}
				restThreshold={0.001}
				minDistance={2}
				maxDistance={8}
				minPolarAngle={Math.PI / 3}
				maxPolarAngle={Math.PI / 2}
				minAzimuthAngle={-Math.PI / 6}
				maxAzimuthAngle={Math.PI / 6}
				truckSpeed={0}
				dollyToCursor
			/>
			{/* <directionalLight
				ref={directionalLight}
				color={"beige"}
				shadow-normalBias={0.05}
				castShadow
				intensity={3}
				position={[10, 10, 7]}
			/> */}
			<ambientLight intensity={1} />
			<Center>
				{/* <TeejaysRoom
					setActiveScreen={setActiveScreen}
					setActive={setActive}
					position={[1, 2, 0]}
					rotation-y={-Math.PI}
				/> */}
				<TeejaysRoomBaked
					setActiveScreen={setActiveScreen}
					setActive={setActive}
					rotation-y={-Math.PI}
				/>
			</Center>
			<fog
				attach='fog'
				args={["#674fe3", 12, 20]}
			/>
			<color
				attach='background'
				args={["#674fe3"]}
			/>
			<mesh
				rotation-x={-Math.PI / 2}
				position-y={-2.2}>
				<planeGeometry args={[100, 40]} />
				<MeshReflectorMaterial
					blur={[400, 100]}
					resolution={1024}
					mixBlur={1}
					mixStrength={80}
					roughness={0.8}
					depthScale={1.2}
					minDepthThreshold={0.4}
					maxDepthThreshold={1.4}
					color='#050505'
					metalness={0.5}
				/>
			</mesh>
		</>
	);
}

export default Experience;
