import Project from "@/features/Projects";
import Section from "@/mobile/Component/Section";
import React from "react";
import "./MobileProjects.css";

function MobileProjects() {
	return (
		<Section>
			<Project />
		</Section>
	);
}

export default MobileProjects;
