import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
	faGithub,
	faLinkedin,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { setNav } from "@/redux/slices/navSlice";
import { useDispatch } from "react-redux";

const SOCIALS = [
	{
		name: "LinkedIn",
		link: "https://www.linkedin.com/in/temitope-james",
		icon: faLinkedin,
		image: "/images/LinkedIn.jpg",
	},
	{
		name: "GitHub",
		link: "https://github.com/sametj",
		icon: faGithub,
	},
	{
		name: "Twitter",
		link: "https://twitter.com/same_teejay",
		icon: faTwitter,
	},
	{
		name: "Email",
		link: "mailto: mosesjames2000@gmail.com",
		icon: faEnvelope,
	},
];

function Contact({ screenRef, setMonitor }) {
	const dispatch = useDispatch();
	const handleClick = () => {
		setMonitor(screenRef);
		dispatch(setNav("Contact"));
	};

	return (
		<section
			className='contact'
			onClick={handleClick}>
			<div className='contact_container app_padding'>
				<div className='contact_text app_padding'>
					<h1>Contact Me</h1>
					<p>You can reach out to me on any of the following platforms:</p>
				</div>

				<div className='socials app_padding'>
					{SOCIALS.map((social) => (
						<a
							className='social_card'
							href={social.link}
							target='_blank'
							rel='noreferrer'
							key={social.name}>
							<div className='social_item app_padding'>
								<FontAwesomeIcon
									className='social_icon'
									icon={social.icon}
									size='2xl'
								/>
								<span>{social.name}</span>
							</div>
						</a>
					))}
				</div>
			</div>
		</section>
	);
}

export default Contact;
