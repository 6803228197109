import About from "@/features/About";
import Section from "@/mobile/Component/Section";
import React from "react";
import "./MobileAbout.css";

function MobileAbout() {
	return (
		<Section>
			<About />
		</Section>
	);
}

export default MobileAbout;
