import { useWindowSize } from "@uidotdev/usehooks";
import DesktopLayout from "./DesktopLayout";
import Mobile from "./mobile/Mobile";

function UseLayout() {
	const { width } = useWindowSize();
	return width >= 1200 ? <DesktopLayout /> : <Mobile />;
}

export default UseLayout;
