import React, { useState } from "react";
import "./Project.css";
import ProjectCard from "./ProjectCard";
import { PROJECTS } from "./Projects/Projects";

//Redux
import { setNav } from "@/redux/slices/navSlice";
import { useDispatch } from "react-redux";

const PROJECTS_PER_PAGE = 3;

function Project({ screenRef, setMonitor }) {
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch();

	const totalPages = Math.ceil(PROJECTS.length / PROJECTS_PER_PAGE);

	const handleClick = () => {
		setMonitor(screenRef);
		dispatch(setNav("Projects"));
	};

	const currentProjects = PROJECTS.slice(
		(currentPage - 1) * PROJECTS_PER_PAGE,
		currentPage * PROJECTS_PER_PAGE
	);

	return (
		<section
			className='project'
			onClick={handleClick}>
			<div className='project_cards app_padding'>
				{/* <button
						onClick={() => handlePrevPage()}
						className='left_arrow'>
						<img src='/images/002-left-arrow.png' />
					</button> */}
				<div className='card_container'>
					{currentProjects.map((project) => (
						<ProjectCard
							key={project.title}
							description={project.description}
							title={project.title}
							stack={project.stack}
							image={project.image}
							live_link={project.live_link}
							github_link={project.github_link}
						/>
					))}
				</div>
				{/* <button
						onClick={() => handleNextPage()}
						className='right_arrow'>
						<img src='/images/001-right-arrow.png' />
					</button> */}
			</div>
		</section>
	);
}

export default Project;
