import "./Nav.css";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNav } from "@/redux/slices/navSlice";

const NAVS = ["Home", "About", "Projects", "Contact", "Hobby"];

function Nav() {
	const currentNav = useSelector((state) => state.nav.value);
	const dispatch = useDispatch();

	const handleLeftClick = () => {
		const currentIndex = NAVS.indexOf(currentNav);
		if (currentIndex > 0) {
			dispatch(setNav(NAVS[currentIndex - 1]));
		}
	};

	const handleRightClick = () => {
		const currentIndex = NAVS.indexOf(currentNav);
		if (currentIndex < NAVS.length - 1) {
			dispatch(setNav(NAVS[currentIndex + 1]));
		}
	};

	const handleXClick = () => {
		if (currentNav === "Home") {
			const landingPage = document.querySelector(".landing_page");
			document.querySelector(".nav_container").style.display = "none";
			landingPage.classList.add("fade_in");
			landingPage.style.display = "flex";

			landingPage.addEventListener(
				"transitionend",
				() => {
					landingPage.classList.remove("fade_in");
				},
				{ once: true }
			);
		} else {
			dispatch(setNav("Home"));
		}
	};

	return (
		<div className='nav_container'>
			<button
				onClick={() => handleLeftClick()}
				className='left_arrow'>
				<img src='/images/002-left-arrow.png' />
			</button>
			<span className='nav_button_text'>{currentNav}</span>
			<button
				onClick={() => handleRightClick()}
				className='right_arrow'>
				<img src='/images/001-right-arrow.png' />
			</button>

			<button
				// onClick={() => handleXClick()}
				className='close'>
				<img src='/images/x-mark.png' />
			</button>
		</div>
	);
}

export default Nav;
