import { Canvas } from "@react-three/fiber";
import { Preload, PerformanceMonitor } from "@react-three/drei";
import { Suspense, useState } from "react";
import LandingPage from "@/features/Landing";
import Loading from "@/features/Loading/Loading";
import Nav from "./features/Nav/Index";

import { useInView } from "react-intersection-observer";

import React from "react";
import Experience from "./Experience";

const cameraSetting = {
	fov: 45,
	near: 0.1,
	far: 1000,
	position: [0, 5, 9],
};

function DesktopLayout() {
	const { ref, inView } = useInView();
	const [dpr, setDpr] = useState(1);

	return (
		<Suspense fallback={<Loading />}>
			<LandingPage />
			<Nav />
			<Canvas
				linear
				ref={ref}
				dpr={dpr}
				shadows
				camera={cameraSetting}>
				<PerformanceMonitor
					onIncline={() => setDpr(2)}
					onDecline={() => setDpr(0.5)}
				/>
				<Experience inView={inView} />
				<Preload all />
			</Canvas>
		</Suspense>
	);
}

export default DesktopLayout;
