import Layout from "@/layout/Main";
import React from "react";

import Home from "./pages/Home/Home";
import MobileAbout from "./pages/About/MobileAbout";
import MobileProjects from "./pages/Projects/MobileProjects";
import MobileContact from "./pages/Contact/MobileContact";
import { Footer } from "@/layout/Main/Main";

function Mobile() {
	return (
		<Layout>
			<Home />
			<MobileAbout />
			<MobileProjects />
			<MobileContact />
			<Footer />
		</Layout>
	);
}

export default Mobile;
