import React from "react";
import "./Tooltip.css";

function Tooltip({ text }) {
	return (
		<>
			<div className='thought'>
				<span>{text || "Some text goes here"}</span>
			</div>
		</>
	);
}

export default Tooltip;
