import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

export const cameraSetting = {
	fov: 50,
	near: 0.1,
	far: 1000,
	position: [0, 5, 9],
};

export const useCameraControls = (active, activeScreen, currentNav) => {
	const cameraRef = useRef();

	useFrame(() => {
		if (!active && currentNav === "Home") {
			return;
		} else if (activeScreen && active) {
			cameraRef.current?.fitToBox(activeScreen, true);
		}
	});

	return cameraRef;
};
