import React from "react";
import {
	BUTTONACTIVE,
	BUTTONACTIVETEXTCOLOR,
	BUTTONINACTIVE,
	BUTTONINACTIVETEXTCOLOR,
} from "@/styles/js/Colors";

function AboutButton({ changeContent, title, button }) {
	return (
		<button
			style={{
				backgroundColor: title === button.title ? BUTTONACTIVE : BUTTONINACTIVE,
				color:
					title === button.title
						? BUTTONACTIVETEXTCOLOR
						: BUTTONINACTIVETEXTCOLOR,
			}}
			key={button.title}
			className='nav_button'
			onClick={() => changeContent(button.title, button.content)}>
			{button.title}
		</button>
	);
}

export default AboutButton;
