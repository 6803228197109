import { useGLTF, Html } from "@react-three/drei";
import { act, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Provider, useSelector } from "react-redux";
import Contact from "@/features/Contact";
import About from "@/features/About";
import Project from "@/features/Projects";
import SimScreen from "@/features/SimScreen";
import Tooltip from "@/features/Tooltip/Tooltip";
import store from "@/redux/store";

export default function TeejaysRoom(props) {
	const { nodes, materials } = useGLTF("/models/TeejaysRoom.glb");
	const { setActiveScreen } = props;
	const { setActive } = props;

	for (const material in materials) {
		materials[material].toneMapped = false;

		if (materials[material].name === "WellLedPurple") {
			materials[material].emissiveIntensity = 20;
			materials[material].metalness = 1;
			materials[material].roughness = 0.2;
			materials[material].toneMapped = true;
			materials[material].emissive = { r: 0.212, g: 0.0, b: 0.247 };
		}

		if (
			materials[material].name === "WallLightBeige" ||
			materials[material].name === "Screen"
		) {
			materials[material].emissiveIntensity = 60;
			materials[material].metalness = 1;
			materials[material].roughness = 0;
			materials[material].toneMapped = false;
			materials[material].emissive = { r: 1, g: 0.9, b: 0 };
		}
	}

	const currentNav = useSelector((state) => state.nav.value);

	const MiddleMonitorScreen = useRef();
	const LeftMonitorScreen = useRef();
	const RightMonitorScreen = useRef();
	const SimMontorScreen = useRef();

	useEffect(() => {
		if (currentNav === "Home") {
			setActiveScreen(null);
			setActive(false);
		} else if (currentNav === "About") {
			setActiveScreen(LeftMonitorScreen.current);
			setActive(true);
		} else if (currentNav === "Projects") {
			setActiveScreen(RightMonitorScreen.current);
			setActive(true);
		} else if (currentNav === "Contact") {
			setActiveScreen(MiddleMonitorScreen.current);
			setActive(true);
		} else if (currentNav === "Hobby") {
			setActiveScreen(SimMontorScreen.current);
			setActive(true);
		}
	}, [currentNav]);

	const setMonitor = (ref) => {
		setActiveScreen(ref.current);
		setActive(true);
	};

	return (
		<group
			{...props}
			dispose={null}>
			<group name='Scene'>
				<group
					name='Desk'
					position={[-0.674, 0.933, 4.628]}>
					<mesh
						name='Plane010'
						castShadow
						receiveShadow
						geometry={nodes.Plane010.geometry}
						material={materials.Black}
					/>
					<mesh
						name='Plane010_1'
						castShadow
						receiveShadow
						geometry={nodes.Plane010_1.geometry}
						material={materials.Beige}
					/>
				</group>
				<mesh
					name='Cube'
					castShadow
					receiveShadow
					geometry={nodes.Cube.geometry}
					material={materials.Purple}
					position={[0, 1.079, 0]}
				/>
				<mesh
					name='Plane'
					castShadow
					receiveShadow
					geometry={nodes.Plane.geometry}
					material={materials.Beige}
					position={[0, 0.212, 0]}
				/>
				<mesh
					name='FloorMat'
					castShadow
					receiveShadow
					geometry={nodes.FloorMat.geometry}
					material={materials.Purple}
					position={[0.017, 0.212, 3.24]}
				/>
				<group
					name='Cube001'
					position={[0.373, 1.264, 3.347]}
					rotation={[0, 0, 0.001]}
					scale={[0.997, 0.92, 1]}>
					<mesh
						name='Cube001_1'
						castShadow
						receiveShadow
						geometry={nodes.Cube001_1.geometry}
						material={materials.Beige}
					/>
					<mesh
						name='Cube001_2'
						castShadow
						receiveShadow
						geometry={nodes.Cube001_2.geometry}
						material={materials.Black}
					/>
				</group>
				<group
					name='Cube002'
					position={[0.727, 1.038, -0.201]}
					rotation={[0, 0, 0.001]}
					scale={[0.997, 0.92, 1]}>
					<mesh
						name='Cube050'
						castShadow
						receiveShadow
						geometry={nodes.Cube050.geometry}
						material={materials.Beige}
					/>
					<mesh
						name='Cube050_1'
						castShadow
						receiveShadow
						geometry={nodes.Cube050_1.geometry}
						material={materials.Black}
					/>
				</group>
				<group
					name='PcCase'
					position={[-0.581, 1.21, 1.151]}
					rotation={[Math.PI, -1.546, Math.PI]}>
					<mesh
						name='Cube005'
						castShadow
						receiveShadow
						geometry={nodes.Cube005.geometry}
						material={materials.PcCase}
					/>
					<mesh
						name='Cube005_1'
						castShadow
						receiveShadow
						geometry={nodes.Cube005_1.geometry}
						material={materials.Glass}
					/>
					<mesh
						name='Cube005_2'
						castShadow
						receiveShadow
						geometry={nodes.Cube005_2.geometry}
						material={materials.Black}
					/>
					<group
						name='PcCover'
						position={[0.003, 0, -0.095]}
						rotation={[Math.PI / 2, 0, 0]}>
						<mesh
							name='Plane042'
							castShadow
							receiveShadow
							geometry={nodes.Plane042.geometry}
							material={materials.Black}
						/>
						<mesh
							name='Plane042_1'
							castShadow
							receiveShadow
							geometry={nodes.Plane042_1.geometry}
							material={materials.Glass}
						/>
					</group>
					<group
						name='WaterPump'
						position={[0.077, 0.205, -0.014]}>
						<mesh
							name='Cube016'
							castShadow
							receiveShadow
							geometry={nodes.Cube016.geometry}
							material={materials.Black}
						/>
						<mesh
							name='Cube016_1'
							castShadow
							receiveShadow
							geometry={nodes.Cube016_1.geometry}
							material={materials.WallLightBeige}
						/>
						<mesh
							name='Cube016_2'
							castShadow
							receiveShadow
							geometry={nodes.Cube016_2.geometry}
							material={materials.WellLedPurple}
						/>
					</group>
				</group>
				<group
					name='Books006'
					position={[-0.442, 0.374, 1.572]}
					rotation={[Math.PI / 2, 0, 2.804]}>
					<mesh
						name='Cube041'
						castShadow
						receiveShadow
						geometry={nodes.Cube041.geometry}
						material={materials.Black}
					/>
					<mesh
						name='Cube041_1'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_1.geometry}
						material={materials.BookWhite}
					/>
					<mesh
						name='Cube041_2'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_2.geometry}
						material={materials.Blue}
					/>
					<mesh
						name='Cube041_3'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_3.geometry}
						material={materials.Brown}
					/>
					<mesh
						name='Cube041_4'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_4.geometry}
						material={materials.Red}
					/>
					<mesh
						name='Cube041_5'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_5.geometry}
						material={materials.Purple}
					/>
					<mesh
						name='Cube041_6'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_6.geometry}
						material={materials.Beige}
					/>
					<mesh
						name='Cube041_7'
						castShadow
						receiveShadow
						geometry={nodes.Cube041_7.geometry}
						material={materials.Green}
					/>
				</group>
				<mesh
					name='SimFrame'
					castShadow
					receiveShadow
					geometry={nodes.SimFrame.geometry}
					material={materials.Black}
					position={[-0.337, 0.417, 0.174]}
				/>
				<group
					name='WheelBase'
					position={[0.138, 0.887, -0.274]}
					rotation={[Math.PI / 2, 0, Math.PI / 2]}>
					<mesh
						name='Plane039'
						castShadow
						receiveShadow
						geometry={nodes.Plane039.geometry}
						material={materials.Black}
					/>
					<mesh
						name='Plane039_1'
						castShadow
						receiveShadow
						geometry={nodes.Plane039_1.geometry}
						material={materials.WheelButton}
					/>
				</group>
				<mesh
					name='MonitorFrame'
					castShadow
					receiveShadow
					geometry={nodes.MonitorFrame.geometry}
					material={materials.Black}
					position={[-0.167, 0.207, 0.44]}
				/>
				<mesh
					name='Pedals'
					castShadow
					receiveShadow
					geometry={nodes.Pedals.geometry}
					material={materials.Black}
					position={[-0.747, 0.393, -0.423]}
					rotation={[0, 0, 0.113]}
				/>
				<mesh
					name='LeftMonitor'
					castShadow
					receiveShadow
					geometry={nodes.LeftMonitor.geometry}
					material={materials.Black}
					position={[-0.969, 1, 3.805]}
				/>
				<mesh
					name='RightMonitor'
					castShadow
					receiveShadow
					geometry={nodes.RightMonitor.geometry}
					material={materials.Black}
					position={[-3.378, 1, 3.808]}
				/>
				<mesh
					name='TopMonitor'
					castShadow
					receiveShadow
					geometry={nodes.TopMonitor.geometry}
					material={materials.Black}
					position={[-0.995, 1.958, 3.082]}
					rotation={[0, 0, -Math.PI / 2]}
				/>
				<mesh
					name='Plane013'
					castShadow
					receiveShadow
					geometry={nodes.Plane013.geometry}
					material={materials.Black}
					position={[-0.091, 1.181, -0.219]}
					rotation={[0, 0, -Math.PI / 2]}
				/>
				<mesh
					name='Mouse'
					castShadow
					receiveShadow
					geometry={nodes.Mouse.geometry}
					material={materials.Black}
					position={[-0.536, 1.04, 3.061]}
					rotation={[Math.PI, 0, Math.PI]}
				/>
				<mesh
					name='MousePad'
					castShadow
					receiveShadow
					geometry={nodes.MousePad.geometry}
					material={materials.Black}
					position={[-0.549, 1.014, 3.401]}
					rotation={[Math.PI, 0, Math.PI]}
				/>
				<group
					name='DeskHeadphone'
					position={[-0.481, 1.06, 2.243]}
					rotation={[Math.PI, 0, 1.36]}>
					<mesh
						name='Circle002'
						castShadow
						receiveShadow
						geometry={nodes.Circle002.geometry}
						material={materials.Black}
					/>
					<mesh
						name='Circle002_1'
						castShadow
						receiveShadow
						geometry={nodes.Circle002_1.geometry}
						material={materials.Purple}
					/>
				</group>
				<mesh
					name='Plane004'
					castShadow
					receiveShadow
					geometry={nodes.Plane004.geometry}
					material={materials.Black}
					position={[-0.661, 0.196, 1.142]}
				/>
				<mesh
					name='Keyboard'
					castShadow
					receiveShadow
					geometry={nodes.Keyboard.geometry}
					material={materials.Black}
					position={[-0.657, 1.035, 3.601]}
					rotation={[Math.PI, 0, Math.PI]}>
					<group
						name='KeyCaps'
						position={[-0.017, 0.048, -0.315]}
						rotation={[0, 0, 0.086]}>
						<mesh
							name='Plane030'
							castShadow
							receiveShadow
							geometry={nodes.Plane030.geometry}
							material={materials.Black}
						/>
						<mesh
							name='Plane030_1'
							castShadow
							receiveShadow
							geometry={nodes.Plane030_1.geometry}
							material={materials.Beige}
						/>
					</group>
				</mesh>
				<group
					name='WallLedLights003'
					position={[-0.746, 2.032, 1.009]}
					rotation={[2.617, 0, Math.PI / 2]}>
					<mesh
						name='Circle005'
						castShadow
						receiveShadow
						geometry={nodes.Circle005.geometry}
						material={materials.BaseWallLed}
					/>
					<mesh
						name='Circle005_1'
						castShadow
						receiveShadow
						geometry={nodes.Circle005_1.geometry}
						material={materials.WallLightBeige}
					/>
					<mesh
						name='Circle005_2'
						castShadow
						receiveShadow
						geometry={nodes.Circle005_2.geometry}
						material={materials.WellLedPurple}
					/>
				</group>
				<mesh
					ref={LeftMonitorScreen}
					name='LeftMonitorScreen'
					castShadow
					receiveShadow
					geometry={nodes.LeftMonitorScreen.geometry}
					material={materials.Screen}
					position={[-0.969, 1, 3.785]}>
					<Html
						prepend
						scale={0.059}
						occlude='blending'
						rotation-x={Math.PI}
						rotation-z={Math.PI}
						position={[3.97, 1.54, -4.32]}
						transform>
						<Provider store={store}>
							<About
								screenRef={LeftMonitorScreen}
								setMonitor={setMonitor}
							/>
						</Provider>
					</Html>
				</mesh>
				<mesh
					ref={RightMonitorScreen}
					name='RightMonitorScreen'
					castShadow
					receiveShadow
					geometry={nodes.RightMonitorScreen.geometry}
					material={materials.Screen}
					position={[-3.378, 1, 3.804]}>
					<Html
						prepend
						scale={0.059}
						occlude='blending'
						rotation-x={Math.PI}
						rotation-z={Math.PI}
						position={[3.97, 1.54, -4.32]}
						transform>
						<Provider store={store}>
							<Project
								screenRef={RightMonitorScreen}
								setMonitor={setMonitor}
							/>
						</Provider>
					</Html>
				</mesh>
				<mesh
					ref={MiddleMonitorScreen}
					name='TopMonitorScreen'
					castShadow
					receiveShadow
					geometry={nodes.TopMonitorScreen.geometry}
					material={materials.Screen}
					position={[-0.995, 1.958, 3.069]}
					rotation={[0, 0, -Math.PI / 2]}>
					<Html
						prepend
						scale={0.08}
						occlude='blending'
						rotation-x={Math.PI}
						rotation-z={Math.PI / 2}
						position={[-1.868, 2.716, -3.55]}
						transform>
						<Provider store={store}>
							<Contact
								screenRef={MiddleMonitorScreen}
								setMonitor={setMonitor}
							/>
						</Provider>
					</Html>
				</mesh>
				<mesh
					ref={SimMontorScreen}
					name='Plane001'
					castShadow
					receiveShadow
					geometry={nodes.Plane001.geometry}
					material={materials.SimScreen}
					position={[-0.091, 1.181, -0.224]}
					rotation={[0, 0, -Math.PI / 2]}>
					<Html
						prepend
						visible={currentNav === "Hobby" ? true : false}
						scale={0.15}
						occlude={currentNav === "Hobby" ? "raycast" : "blending"}
						rotation-x={Math.PI}
						rotation-z={Math.PI * 0.5}
						position={[-1.9, -4.7, -1.83]}
						transform>
						<Tooltip text={"Me Driving 🏎️💨 "} />
					</Html>
					<Html
						scale={0.05}
						occlude='blending'
						rotation-y={Math.PI}
						rotation-z={-Math.PI * 0.5}
						position={[-1.18, -4, -1.83]}
						transform>
						<Provider store={store}>
							<SimScreen
								screenRef={SimMontorScreen}
								setMonitor={setMonitor}
							/>
						</Provider>
					</Html>
				</mesh>
			</group>
		</group>
	);
}

useGLTF.preload("/models/TeejaysRoom.glb");
