import React from "react";
import ReactDOM from "react-dom/client";
import "@/styles/css/index.css";
import "@/styles/css/cssReset.css";
import { Provider } from "react-redux";
import store from "@/redux/store";
import UseLayout from "./UseLayout";

ReactDOM.createRoot(document.getElementById("root")).render(
	<>
		<Provider store={store}>
			<UseLayout />
		</Provider>
	</>
);
